import { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useNavigate } from 'react-router-dom'

function SliderPatent() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [swiper, setSwiper] = useState(null)
  const clickBtn = () => {
    navigate('/business/patent')
  }
  useEffect(() => {
    fetch('/data/Main/SliderPatent/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        if (swiper) {
          const timer = setTimeout(() => {
            window.screen.width > 768 ? swiper.slideTo(5) : swiper.slideTo(1)
          }, 1)
          return () => clearTimeout(timer)
        }
      })
  }, [swiper])

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <PatentWrapper>
      <Header>
        <Label>
          <hr />
          business
        </Label>
        <Title>Patent</Title>
        <SubText>에스프레스토가 소유한 특허를 소개합니다.</SubText>
        <SliderIndexBox>
          <hr />
          <SlideIndex className="slideIndex_tech4" />
        </SliderIndexBox>
      </Header>
      <NavigationButton>
        <PrevButton ref={navigationPrevRef}>
          <img src="/assets/icons/prevbtn_blue.png" alt="이전 슬라이드 버튼" />
        </PrevButton>
        <SlideIndex className="slideIndex" />
        <NextButton ref={navigationNextRef}>
          <img src="/assets/icons/nextbtn_blue.png" alt="다음 슬라이드 버튼" />
        </NextButton>
      </NavigationButton>
      {window.screen.width > 768 ? (
        <StyleSwiper
          onSwiper={(s) => {
            setSwiper(s)
          }}
          slidesPerView={4.2}
          loop={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }}
          pagination={{
            type: 'fraction',
            el: '.slideIndex',
            formatFractionCurrent: function (number) {
              if (number < 10) {
                return '0' + number
              }
              return number
            },
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <Content>
                <Certificate>
                  <img src={data.image} alt="특허증" />
                </Certificate>
                <Info>
                  <Stars>{data.stars}</Stars>
                  <Name>{data.title}</Name>
                  <Number>{data.number}</Number>
                  <Text>{data.content}</Text>
                </Info>
              </Content>
            </SwiperSlide>
          ))}
        </StyleSwiper>
      ) : (
        <StyleSwiper
          loop={true}
          pagination={{
            type: 'progressbar',
            el: '.slideIndex_tech4',
          }}
          onSwiper={(s) => {
            setSwiper(s)
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <Content>
                <img
                  className="medal"
                  src="/assets/images/main/mobile/patent/medal.png"
                  alt="medal"
                />
                <Info>
                  <Stars>{data.stars}</Stars>
                  <Name>{data.title}</Name>
                  <Number>{data.number}</Number>
                  <Text>{data.content}</Text>
                </Info>
              </Content>
            </SwiperSlide>
          ))}
          <ViewMoreBtn onClick={clickBtn}>
            VIEW MORE
            <span>
              <img src="/assets/icons/mo_right_arrow.png" alt="arrow" />
            </span>
          </ViewMoreBtn>
        </StyleSwiper>
      )}
    </PatentWrapper>
  )
}

const PatentWrapper = styled.section`
  position: relative;
  padding: 60px;
  min-width: 1440px;
  height: 1024px;
  background-color: ${(props) => props.theme.color.GTertiary};
  background-image: url(/assets/images/main/patent/patent_bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  .swiper-slide-active {
    margin-right: 300px;
    article {
      width: 600px;
      transition: all 0.5s ease-in-out;
      aside {
        padding: 40px;
        width: 300px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media all and (max-width: 768px) {
    background-image: url(/assets/images/main/mobile/patent/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem 2rem;
    min-width: 100%;
    height: 52rem;
    .swiper-slide-active {
      article {
        width: 100%;
      }
    }
    .swiper-slide {
      padding: 0.5rem;
    }
  }
`
//title
const Header = styled.header`
  margin-top: 180px;
  color: ${(props) => props.theme.color.GPrimary};

  @media all and (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`
const Label = styled.div`
  hr {
    display: inline-block;
    position: absolute;
    top: 2px;
    left: -45px;
    width: 30px;
    height: 2px;
    border: none;
    background-color: ${(props) => props.theme.color.GPrimary};
  }
  margin-bottom: 20px;
  position: relative;
  margin-left: 40px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 5px;
`
const Title = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  @media all and (max-width: 768px) {
    font-size: 2.4rem;
  }
`
const SubText = styled.p`
  margin-top: 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  @media all and (max-width: 768px) {
    max-width: 20rem;
    font-size: 1rem;
    margin-top: 0;
    word-break: keep-all;
  }
`

//slider - prev&next button
const NavigationButton = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  position: absolute;
  width: 244px;
  height: 60px;
  top: 380px;
  right: 60px;
  Button {
    border: none;
    outline: none;
    opacity: 0.8;
    cursor: pointer;
    background: none;
    transition: all 0.2s ease-in;
    z-index: 1;
    filter: drop-shadow(0px 15px 30px rgba(0, 186, 199, 0.2));
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`
const PrevButton = styled.button``
const NextButton = styled.button``

const SlideIndex = styled.p`
  color: ${(props) => props.theme.color.GPrimary};
  text-align: center;
  color: rgba(0, 186, 199, 0.5);
  line-height: 55px;
  font-family: Montserrat;
  span {
    display: inline-block;
  }
  .swiper-pagination-current {
    width: 40px;
    margin-right: 15px;
    color: #00bac7;
    font-weight: 600;
    font-size: 40px;
  }
  .swiper-pagination-total {
    width: 20px;
    margin-left: 10px;
    font-size: 16px;
    line-height: 60px;
    opacity: 0.5;
  }
`
//slider
const StyleSwiper = styled(Swiper)`
  margin: 100px -60px auto -60px;
  padding-left: 60px;
  .swiper-wrapper {
    height: 500px;
  }
  @media all and (max-width: 768px) {
    padding-left: 0;
    margin: 3rem auto;
  }
`
//slider content
const Content = styled.article`
  display: flex;
  width: 300px;
  height: 400px;
  margin-right: 150px;
  border-radius: 8px;
  word-break: break-all;
  background-color: white;
  box-shadow: 0px 30px 60px rgba(0, 186, 199, 0.15);
  .medal {
    display: none;
  }
  @media all and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
    box-shadow: 5px 5px 15px rgb(0 186 199 / 15%);
    height: 22rem;
    .medal {
      display: block;
      position: absolute;
      right: 3rem;
    }
  }
`
//slider content - image
const Certificate = styled.aside`
  padding: 0px;
  width: 0px;
  img {
    width: 0px;
    height: 400px;
  }
`
//slider content - text
const Info = styled.div`
  padding: 40px;
  width: 300px;
  height: 100%;
  text-align: center;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`
const Stars = styled.div`
  margin-bottom: 20px;
  font-size: 24px;
  letter-spacing: 5px;
  color: ${(props) => props.theme.color.GPrimary};

  @media all and (max-width: 768px) {
    display: none;
  }
`
const Name = styled.p`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  @media all and (max-width: 768px) {
    text-align: left;
  }
`
const Number = styled.div`
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  @media all and (max-width: 768px) {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 4rem;
  }
`
const Text = styled.p`
  width: 220px;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: ${(props) => props.theme.color.TPrimary};
  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
  }
`
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: -425px;
  line-height: 18px;
  font-family: Montserrat;
  display: none;

  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
  @media all and (max-width: 768px) {
    display: block;
    width: 100%;
    bottom: -31.3rem;

    hr {
      display: none;
    }

    .swiper-pagination-progressbar {
      background: rgba(0, 186, 199, 0.3);
      height: 0.13rem;
    }
    .swiper-pagination-progressbar-fill {
      background: #00bac7;
    }
  }
`

const ViewMoreBtn = styled.article`
  display: none;
  @media all and (max-width: 768px) {
    float: right;
    display: block;
    color: #00bac7;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3em;
    span {
      margin-left: 0.5rem;
    }
  }
`

export default SliderPatent
