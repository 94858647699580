import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'

function History() {
  const subtext =
    window.screen.width > 768
      ? `에스프레스토는 ‘Software (소프트웨어)’의 ’S’와 ‘매우 빠르게’를 의미하는 음악 용어 ‘Presto (프레스토)’를 합쳐 만들어진 이름입니다.
설립부터 지금까지 끊임없는 기술 혁신을 통해 사회 문제를 해결하고, 선한 이윤을 창출하고자 노력하고 있습니다.`
      : `에스프레스토는 ‘Software (소프트웨어)’의 ’S’와\n‘매우 빠르게’를 의미하는 음악 용어 ‘Presto(프레스토)’를\n합쳐 만들어진 이름입니다.\n설립부터 지금까지 끊임없는 기술 혁신을 통해\n사회 문제를 해결하고, 선한 이윤을 창출하고자\n노력하고 있습니다.`
  return (
    <>
      <PageTitle title="History" subtext={subtext} />
      <HistoryWrapper>
        <Content>
          <Container>
            <Point className="bigicon">
              <img src="/assets/icons/history_01.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2022</Year>
              <Story>안심숙박앱 ‘체크인' 런칭</Story>
              <Story>2022 제8회 대한민국 기업대상 'ESG' 분야 대상 수상</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2021</Year>
              <Story>벤처기업 등록 (벤처기업부)</Story>
              <Story>스노우볼투자조합 13호 투자유치</Story>
              <Story>
                수원도시지원센터, 수원도시공사, 마포경찰서 업무협약 체결
              </Story>
              <Story>
                중소기업기술정보진흥원 디딤돌 (사회문제해결형) 과제
                <p>- AI+X 기술 융합을 통한 불법카메라 탐지 모바일 서비스</p>
              </Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2020</Year>
              <Story>몰카탐지앱 ‘릴리의지도' 런칭</Story>
              <Story>중소기업 등록 (중소벤처기업청)</Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2019</Year>
              <Story>카이스트 청년 창업투자지주 주식회사 투자유치</Story>
              <Story>
                서울산업진흥원 서울 혁신챌린지 (예선~결선)
                <p>- 인공지능 기반 개인 사생활 유출 영상 탐지기술</p>
              </Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="smallicon">
              <img src="/assets/icons/history_02.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2017</Year>
              <Story>
                중소벤처기업부 창업선도대학 (~후속)
                <p>- 부분 영상 기반 사용자 중심적 스트리밍 플랫폼</p>
              </Story>
            </TextBundle>
          </Container>
          <Container>
            <Point className="bigicon">
              <img src="/assets/icons/history_01.png" alt="포인트" />
            </Point>
            <TextBundle>
              <Year>2016</Year>
              <Story>에스프레스토(주) 설립</Story>
            </TextBundle>
          </Container>
          <Border />
        </Content>
      </HistoryWrapper>
    </>
  )
}

const HistoryWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  background-color: ${(props) => props.theme.color.WPrimary};
  @media all and (max-width: 768px) {
    min-width: 100%;
  }
`
const Content = styled.article`
  margin: 180px 0;
  padding-left: 135px;
  @media all and (max-width: 768px) {
    margin-top: 3.5rem;
    margin-bottom: 6rem;
    padding: 0 2rem 0 2.5rem;
  }
`
// history
const Container = styled.div`
  display: flex;
  margin-top: 40px;
  width: 1305px;
  .bigicon {
    left: -18px;
    top: 10px;
    z-index: 10;
  }
  .smallicon {
    z-index: 10;
    left: -6px;
    top: 17px;
    padding: 8px 20px 0 0;
  }
  @media all and (max-width: 768px) {
    margin-top: 2rem;
    width: 100%;
    .bigicon {
      top: 0.5rem;
    }

    .smallicon {
      top: 1rem;
    }
    word-break: keep-all;
  }
`
const Point = styled.div`
  position: relative;
`

// history - text
const TextBundle = styled.div`
  margin-left: 75px;
  @media all and (max-width: 768px) {
    margin-left: 0;
  }
`
const Year = styled.p`
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  color: #333333;

  @media all and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-top: 0.6rem;
  }
`
const Story = styled.div`
  margin: 50px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #6d6d6d;
  p {
    padding-top: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #aaaaaa;
  }
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    margin: 1rem 0 0.1rem 0;
    p {
      font-size: 0.8rem;
    }
  }
`
// border
const Border = styled.div`
  position: absolute;
  top: 10px;
  left: 135px;
  height: 1750px;
  border-left: 4px solid ${(props) => props.theme.color.GTransparency};
  @media all and (max-width: 768px) {
    left: 2.53rem;
    height: 89%;
    top: 3rem;
  }
`

export default History
