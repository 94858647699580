import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

function MobileHeader() {
  const navigate = useNavigate()
  const [display, setDisplay] = useState(false)
  const [OpenAboutSubMenu, setOpenAboutSubMenu] = useState(false)
  const [OpenBusinessSubMenu, setOpenBusinessSubMenu] = useState(false)

  const toggleMobileMenu = () => {
    setDisplay((display) => !display) // on,off 개념 boolean
  }
  const toggleAboutSubMenu = () => {
    setOpenAboutSubMenu((OpenAboutSubMenu) => !OpenAboutSubMenu) // on,off 개념 boolean
    setOpenBusinessSubMenu(() => false)
  }
  const toggleBusinessSubMenu = () => {
    setOpenBusinessSubMenu((OpenBusinessSubMenu) => !OpenBusinessSubMenu) // on,off 개념 boolean
    setOpenAboutSubMenu(() => false)
  }

  const move = (url) => {
    navigate(url)
    toggleMobileMenu()
  }

  return (
    <>
      <GNB>
        <Logo
          onClick={() => {
            navigate(`/`)
          }}
        />
        <Btn display={display} onClick={toggleMobileMenu} />
      </GNB>
      <MobileMenu display={display}>
        <MenuItem
          margint={true}
          clicked={OpenAboutSubMenu}
          onClick={toggleAboutSubMenu}
        >
          ABOUT US
          <MenuItemImg clicked={OpenAboutSubMenu} />
        </MenuItem>
        <MenuSubItem
          onClick={() => {
            move(`/about/introduce`)
          }}
          isParentClicked={OpenAboutSubMenu}
        >
          회사소개
        </MenuSubItem>
        <MenuSubItem
          onClick={() => {
            move(`/about/news`)
          }}
          isParentClicked={OpenAboutSubMenu}
        >
          보도자료
        </MenuSubItem>
        <MenuSubItem
          onClick={() => {
            move(`/about/members`)
          }}
          isParentClicked={OpenAboutSubMenu}
        >
          멤버소개
        </MenuSubItem>
        <MenuSubItem
          onClick={() => {
            move(`/about/history`)
          }}
          isParentClicked={OpenAboutSubMenu}
        >
          연혁
        </MenuSubItem>
        <MenuItem clicked={OpenBusinessSubMenu} onClick={toggleBusinessSubMenu}>
          BUSINESS
          <MenuItemImg clicked={OpenBusinessSubMenu} />
        </MenuItem>
        <MenuSubItem
          onClick={() => {
            move(`/business/product`)
          }}
          isParentClicked={OpenBusinessSubMenu}
        >
          프로덕트
        </MenuSubItem>
        <MenuSubItem
          onClick={() => {
            move(`/business/tech`)
          }}
          isParentClicked={OpenBusinessSubMenu}
        >
          기술소개
        </MenuSubItem>
        <MenuSubItem
          onClick={() => {
            move(`/business/patent`)
          }}
          isParentClicked={OpenBusinessSubMenu}
        >
          특허기술
        </MenuSubItem>

        <MenuItem
          onClick={() => {
            const win = window.open(`https://spresto.oopy.io/`, '_blank')
            win.focus()
          }}
        >
          RECRUIT
          <img src="/assets/icons/mobile/header/Arrow.png" alt="arrow" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            move(`/contact`)
          }}
        >
          CONTACT
          <img src="/assets/icons/mobile/header/Arrow.png" alt="arrow" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            const win = window.open(
              ` https://spresto.oopy.io/disclosure/`,
              '_blank'
            )
            win.focus()
          }}
        >
          DISCLOSURE
          <img src="/assets/icons/mobile/header/Arrow.png" alt="arrow" />
        </MenuItem>
      </MobileMenu>
    </>
  )
}

export default MobileHeader

const GNB = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
  height: 4rem;
  background-color: #fff;
  border-bottom: 1px solid #c6f4f8;
  z-index: 20001;

  @media all and (max-width: 768px) {
    display: block;
  }
`

const MobileMenu = styled.div`
  display: none;

  @media all and (max-width: 768px) {
    z-index: 20000;
    display: ${(props) => (props.display ? 'block' : 'none')};
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 4rem;
  }
`

const MenuItem = styled.div`
  width: 100%;
  font-size: 1.2rem;
  height: 5rem;
  line-height: 2.2rem;
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 1.55rem 1rem 1.35rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  color: ${(props) => (props.clicked ? '#00BAC7' : '#666666')};
  margin-top: ${(props) => (props.margint ? '0.5rem' : 'none')};
  z-index: 20000;

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.3rem;
  }
`

const MenuItemImg = styled.div`
  background: ${(props) =>
    props.clicked
      ? "url('/assets/icons/mobile/header/ChevronUp.png') center center no-repeat"
      : "url('/assets/icons/mobile/header/Chevron.png') center center no-repeat"};
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.3rem;
`

const MenuSubItem = styled.div`
  display: ${(props) => (props.isParentClicked ? 'block' : 'none')};
  height: 4rem;
  background: #f1feff;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
  color: #666666;
  padding: 1.4rem 1rem 1.4rem 1.7rem;
`

const Logo = styled.a`
  width: 6rem;
  position: absolute;
  left: 1.5rem;
  top: 1.3rem;
  overflow: hidden;
  display: block;
  height: 35px;
  text-indent: -10000em;
  background: url(/assets/icons/logo_black.png) 0 0 no-repeat;
  background-size: 100%;
`
const Btn = styled.button`
  position: absolute;
  right: 20px;
  top: 1.2rem;
  width: 30px;
  height: 30px;
  background: ${(props) =>
    props.display
      ? "url('/assets/icons/mobile/header/HamburgerX.png') center center no-repeat"
      : "url('/assets/icons/Hamburger.png') center center no-repeat"};
  background-size: 26px auto;
  text-indent: -10000em;
  overflow: hidden;
  border: 0;
`
