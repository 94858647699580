import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import History from './pages/About/History/History'
import Introduce from './pages/About/Introduce/Introduce'
import Members from './pages/About/Members/Members'
import News from './pages/About/News/News'
import Patent from './pages/Business/Patent/Patent'
import Product from './pages/Business/Product/Product'
import Tech from './pages/Business/Tech/Tech'
import Contact from './pages/Contact/Contact'
import Main from './pages/Main/Main'
import Recruit from './pages/Recruit/Recruit'
import Film from './pages/Film/Film'
import Terms from './pages/Terms/Terms'
import Terms1 from './pages/Terms/Terms1'
import Terms2 from './pages/Terms/Terms2'
import Terms3 from './pages/Terms/Terms3'
import Terms4 from './pages/Terms/Terms4'
import ScrollToTop from './ScrollToTop'

function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about">
          <Route path="introduce" element={<Introduce />} />
          <Route path="news" element={<News />} />
          <Route path="history" element={<History />} />
          <Route path="members" element={<Members />} />
        </Route>
        <Route path="/business">
          <Route path="product" element={<Product />} />
          <Route path="tech" element={<Tech />} />
          <Route path="patent" element={<Patent />} />
        </Route>
        <Route path="/film" element={<Film />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms_en" element={<Terms />} />
        <Route path="/terms_jp" element={<Terms />} />
        <Route path="/terms1" element={<Terms1 />} />
        <Route path="/terms1_en" element={<Terms1 />} />
        <Route path="/terms1_jp" element={<Terms1 />} />
        <Route path="/terms2" element={<Terms2 />} />
        <Route path="/terms2_en" element={<Terms2 />} />
        <Route path="/terms2_jp" element={<Terms2 />} />
        <Route path="/terms3" element={<Terms3 />} />
        <Route path="/terms3_en" element={<Terms3 />} />
        <Route path="/terms3_jp" element={<Terms3 />} />
        <Route path="/terms4" element={<Terms4 />} />
        <Route path="/terms4_en" element={<Terms4 />} />
        <Route path="/terms4_jp" element={<Terms4 />} />

        <Route path="/terms_1" element={<Terms1 />} />
        <Route path="/terms_2" element={<Terms2 />} />
        <Route path="/terms_3" element={<Terms3 />} />
        <Route path="/terms_4" element={<Terms4 />} />
        <Route path="/terms_1.html" element={<Terms1 />} />
        <Route path="/terms_2.html" element={<Terms2 />} />
        <Route path="/terms_3.html" element={<Terms3 />} />
        <Route path="/terms_4.html" element={<Terms4 />} />
        <Route path="/terms_en_1.html" element={<Terms1 />} />
        <Route path="/terms_en_2.html" element={<Terms2 />} />
        <Route path="/terms_en_3.html" element={<Terms3 />} />
        <Route path="/terms_en_4.html" element={<Terms4 />} />

        <Route path="*" element={<Recruit />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default Router
