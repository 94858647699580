import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'

function Introduce() {
  const subtext =
    window.screen.width > 768
      ? `에스프레스토는\n"우리 모두가 다시 안심할 수 있게" 라는 하나의 목표를 가지고 정진하는 AI 스타트업입니다.\n에스프레스토는 기술이 훼손한 안심가치를, 다시금 기술로 회복시키고자 합니다.`
      : `에스프레스토는\n"우리 모두가 다시 안심할 수 있게" 라는\n하나의 목표를 가지고 정진하는 AI 스타트업입니다.\n에스프레스토는 기술이 훼손한 안심가치를,\n다시금 기술로 회복시키고자 합니다.`
  return (
    <>
      <PageTitle title="Our Mission" subtext={subtext} />
      <List>
        <Item>
          <img
            className="icon"
            src="/assets/icons/business.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>BUSINESS</Keyword>
          ) : (
            <Keyword>⎯ BUSINESS</Keyword>
          )}

          <Image>
            <img
              src="/assets/images/about/mission/business.png"
              alt="business"
            />
          </Image>
          <p>바르고 지속성 있는</p>
          <p>비지니스 모델을 추구합니다.</p>
        </Item>
        <Item isRight={true}>
          <img
            className="icon"
            src="/assets/icons/technology.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>TECHNOLOGY</Keyword>
          ) : (
            <Keyword>⎯ TECHNOLOGY</Keyword>
          )}

          <Image>
            <img src="/assets/images/about/mission/tech.png" alt="business" />
          </Image>
          <p>혁신적인,</p>
          <p>그리고 실용적인 기술로 변화를 만듭니다.</p>
        </Item>
        <Item>
          <img
            className="icon"
            src="/assets/icons/social.png"
            alt="bussiness icon"
          />
          {window.screen.width > 768 ? (
            <Keyword>SOCIAL</Keyword>
          ) : (
            <Keyword>⎯ SOCIAL</Keyword>
          )}

          <Image>
            <img src="/assets/images/about/mission/social.png" alt="business" />
          </Image>
          <p>수익 추구뿐만 아니라, 우리의 일이 주는</p>
          <p>사회적 의미에 대해 항상 고민합니다.</p>
        </Item>
      </List>
    </>
  )
}

const List = styled.div`
  margin-top: 100px;
  padding: 0 60px;
  margin-bottom: 200px;
  width: 100%;
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  @media all and (max-width: 768px) {
    flex-direction: column;
    padding: 0 2rem;
    margin-top: 0;
    margin-bottom: 5rem;
    align-items: center;
  }
`

const Item = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: ${({ theme }) => theme.color.TPrimary};
  }

  hr {
    display: none;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    align-items: ${(props) => (props.isRight ? 'flex-end' : 'flex-start')};
    margin-top: 2rem;
    .icon {
      display: none;
    }
    p {
      font-size: 0.9rem;
      line-height: 2rem;
    }
  }
`

const Image = styled.div`
  margin-bottom: 30px;
  margin-top: 10px;
  @media all and (max-width: 768px) {
    width: 15rem;

    img {
      width: 100%;
    }
  }
`

const Keyword = styled.h3`
  margin: 24px 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4em;
  color: ${({ theme }) => theme.color.GPrimary};
`

export default Introduce
