import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import MobileHeader from './MobileHeader'

function Header() {
  const location = useLocation()
  const navigate = useNavigate()
  const [whiteMode, setWhiteMode] = useState(false)
  const [OpensubMenu, setOpneSubMenu] = useState(false)
  const [scrollY, setScrollY] = useState()

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset
    setScrollY(scrollPosition)
    if (window.location.pathname === '/' && scrollPosition < 200) {
      setWhiteMode(false)
    } else setWhiteMode(true)
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    } else setWhiteMode(true)
  }, [])

  return (
    <Container whiteMode={whiteMode} OpensubMenu={OpensubMenu}>
      <MobileHeader />
      <Menu
        onMouseOver={() => {
          setWhiteMode(true)
        }}
        onMouseLeave={() => {
          location.pathname === '/' && scrollY < 300 && setWhiteMode(false)
        }}
        whiteMode={whiteMode}
      >
        <Logo
          src={
            whiteMode
              ? '/assets/icons/logo_black.png'
              : '/assets/icons/logo_white.png'
          }
          onClick={() => {
            navigate(`/`)
          }}
        />
        <MenuList whiteMode={whiteMode}>
          <Group
            onMouseOver={() => {
              setOpneSubMenu(true)
            }}
            onMouseLeave={() => {
              setOpneSubMenu(false)
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(`/about/introduce`)
              }}
              hilight={window.location.pathname.includes('about')}
            >
              ABOUT US
            </MenuItem>
            {OpensubMenu && (
              <SubMenuList>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/introduce`)
                  }}
                  hilight={window.location.pathname.includes('introuduce')}
                >
                  회사소개
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/news`)
                  }}
                  hilight={window.location.pathname.includes('news')}
                >
                  보도자료
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/members`)
                  }}
                  hilight={window.location.pathname.includes('members')}
                >
                  멤버소개
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/about/history`)
                  }}
                  hilight={window.location.pathname.includes('history')}
                >
                  연혁
                </SubMenuItem>
              </SubMenuList>
            )}
          </Group>
          <Group
            onMouseOver={() => {
              setOpneSubMenu(true)
            }}
            onMouseLeave={() => {
              setOpneSubMenu(false)
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(`/business/product`)
              }}
              hilight={window.location.pathname.includes('business')}
            >
              BUSINESS
            </MenuItem>
            {OpensubMenu && (
              <SubMenuList>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/product`)
                  }}
                  hilight={window.location.pathname.includes('product')}
                >
                  프로덕트
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/tech`)
                  }}
                  hilight={window.location.pathname.includes('tech')}
                >
                  기술소개
                </SubMenuItem>
                <SubMenuItem
                  onClick={() => {
                    navigate(`/business/patent`)
                  }}
                  hilight={window.location.pathname.includes('patent')}
                >
                  특허기술
                </SubMenuItem>
              </SubMenuList>
            )}
          </Group>
          <MenuItem
            onClick={() => {
              navigate(`/contact`)
            }}
            hilight={window.location.pathname.includes('contact')}
          >
            CONTACT
          </MenuItem>
          <MenuItem
            onClick={() => {
              const win = window.open(`https://spresto.oopy.io/`, '_blank')
              win.focus()
            }}
            hilight={window.location.pathname.includes('recruit')}
          >
            RECRUIT
          </MenuItem>
          <MenuItem
            onClick={() => {
              const win = window.open(
                'https://spresto.oopy.io/disclosure/',
                '_blank'
              )
              win.focus()
            }}
            hilight={window.location.pathname.includes('disclosure')}
          >
            DISCLOSURE
          </MenuItem>
        </MenuList>
      </Menu>
    </Container>
  )
}

export default Header

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 1440px;
  height: ${(props) => (props.OpensubMenu ? '324px' : '99px')};
  transition: background-color 200ms ease-in-out;
  background-color: ${(props) => (props.whiteMode ? '#ffffff' : 'inherit')};
  z-index: 1003;
  @media all and (max-width: 768px) {
    background: none;
    width: 100%;
    z-index: 100;
  }
`

const Menu = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start', 'space-between')}
  width: 100%;
  height: 99px;
  padding: 0 100px;
  border-bottom: 1px solid #c6f4f8;
  /* ${(props) => (props.whiteMode ? '#C6F4F8' : '#ffffff')}; */
  @media all and (max-width: 768px) {
    display: none !important;
  }
`

const Logo = styled.img`
  display: block;
  width: 160px;
  margin-top: 30px;
  cursor: pointer;
`

const MenuList = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'flex-start')};
  color: ${(props) => (props.whiteMode ? '#666666' : '#ffffff')};
  transition: color 200ms ease-in-out;

  :last-child {
    margin-right: 0;
  }
`

const MenuItem = styled.span`
  display: inline-block;
  padding: 40px 0;
  margin: 0 60px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  color: ${(props) => props.hilight && '#00BAC7'};

  &:hover {
    color: ${({ theme }) => theme.color.GPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.color.GPrimary};
  }
`

const Group = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')}
`

const SubMenuList = styled.ul``
const SubMenuItem = styled.li`
  padding: 24px 0 0;
  text-align: center;
  color: ${({ theme }) => theme.color.TSecondary};
  transition: color 200ms ease-in-out;
  cursor: pointer;
  font-family: 'Noto Sans KR';
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  color: ${(props) => props.hilight && '#00BAC7'};

  :last-child {
    padding-bottom: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.GPrimary};
  }
`
const GNB = styled.li`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 33px;
  border-bottom: 1px solid #e5e5e5;
  z-index: 99;
  @media all and (max-width: 768px) {
    display: block;
    height: 42px;
    padding-top: 11px;
    background-color: #fff;
    text-align: center;
  }
`
