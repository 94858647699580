import styled from 'styled-components'

function Mission() {
  return (
    <Container>
      <Header>
        <span>ABOUT US</span>
        <Title>Our Mission</Title>
      </Header>
      <Missions>
        {window.screen.width > 768
          ? `에스프레스토는\n"우리 모두가 다시 안심할 수 있게" 라는 하나의 목표를 가지고 정진하는 AI 스타트업입니다.\n에스프레스토는 기술이 훼손한 안심가치를, 다시금 기술로 회복시키고자 합니다.`
          : `에스프레스토는\n"우리 모두가 다시 안심할 수 있게" 라는\n하나의 목표를 가지고 정진하는 AI 스타트업입니다.\n에스프레스토는 기술이 훼손한 안심가치를,\n다시금 기술로 회복시키고자 합니다.`}
      </Missions>
      <List>
        <Item>
          <img src="/assets/icons/business.png" alt="bussiness icon" />
          <Keyword>BUSINESS</Keyword>
          <p>바르고 지속성 있는</p>
          <p>비지니스 모델을 추구합니다.</p>
        </Item>
        <Item>
          <img src="/assets/icons/technology.png" alt="bussiness icon" />
          <Keyword>TECHNOLOGY</Keyword>
          <p>혁신적인,</p>
          <p>그리고 실용적인 기술로 변화를 만듭니다.</p>
        </Item>
        <Item>
          <img src="/assets/icons/social.png" alt="bussiness icon" />
          <Keyword>SOCIAL</Keyword>
          <p>수익 추구뿐만 아니라, 우리의 일이 주는</p>
          <p>사회적 의미에 대해 항상 고민합니다.</p>
        </Item>
      </List>
    </Container>
  )
}

export default Mission

const Container = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column', 'center', 'flex-start')};
  width: 1440px;
  height: 1024px;
  @media all and (max-width: 768px) {
    width: 100%;
    height: 86rem;
  }
`

const Header = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 120px;

  span {
    display: block;
    width: 155px;
    height: 43px;
    background-color: ${({ theme }) => theme.color.GTertiary};
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: ${({ theme }) => theme.color.GPrimary};

  @media all and (max-width: 768px) {
    font-size: 2.8rem;
  }
`

const Missions = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 100px;
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #6d6d6d;
  white-space: pre-line;

  @media all and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
    max-width: 22rem;
    word-break: keep-all;
    margin-top: 2rem;
    line-height: 2rem;
  }
`

const List = styled.div`
  margin-top: 100px;
  padding: 0 60px;
  width: 100%;
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};

  @media all and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
    padding: 0 1rem;
  }
`

const Item = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};
  width: 500px;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    color: ${({ theme }) => theme.color.TPrimary};
  }

  @media all and (max-width: 768px) {
    width: 100%;
    margin-top: 3rem;
    text-align: center;
  }
`

const Keyword = styled.h3`
  margin: 24px 0 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.4em;
  color: ${({ theme }) => theme.color.GPrimary};
`
