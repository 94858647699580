import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper'
import { useEffect, useState } from 'react'

function SliderMain() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Main/SliderMain/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  return (
    <Container>
      <MoImg />
      <MoTextBox>
        <div className="en">Make all of us relief again</div>
        <div className="ko">우리 모두가 다시 안심할 수 있게</div>
      </MoTextBox>
      <StyledSwiper
        modules={[Pagination, Navigation, Autoplay]}
        direction="vertical"
        slidesPerView={1}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'progressbar',
        }}
        className="mySwiper"
      >
        {data.map((data) => {
          return (
            <SwiperSlide key={data.id}>
              <Img src={data.img} />
            </SwiperSlide>
          )
        })}
      </StyledSwiper>

      <StyledSwiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        direction="vertical"
        effect="fade"
        fadeEffect={{ crossFade: true }}
        slidesPerView={1}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'fraction',
          formatFractionCurrent: function (number) {
            return ('0' + number).slice(-2)
          },
          formatFractionTotal: function (number) {
            return ('0' + number).slice(-2)
          },
          renderFraction: function (currentClass, totalClass) {
            return (
              '<span class="' +
              currentClass +
              '"></span>' +
              ' - ' +
              '<span class="' +
              totalClass +
              '"></span>'
            )
          },
        }}
        className="mySwiper SwiperBody"
      >
        <ContentBox />

        {data.map((data) => {
          return (
            <SwiperSlide key={data.id}>
              <Content>
                <TitleE>{data.titleE}</TitleE>
                <TitleK>{data.titleK}</TitleK>
              </Content>
            </SwiperSlide>
          )
        })}
      </StyledSwiper>
      <ArrowButton
        onClick={() => {
          window.screen.width > 768
            ? window.scrollTo({ left: 0, top: 925, behavior: 'smooth' })
            : window.scrollTo({ left: 0, top: 650, behavior: 'smooth' })
        }}
      />
      <ArrowButton2
        onClick={() => {
          window.screen.width > 768
            ? window.scrollTo({ left: 0, top: 925, behavior: 'smooth' })
            : window.scrollTo({ left: 0, top: 650, behavior: 'smooth' })
        }}
      />
    </Container>
  )
}

export default SliderMain

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 1440px;
    height: 1024px;
  }

  .swiper-pagination {
    color: transparent;

    .swiper-pagination-current,
    .swiper-pagination-total {
      color: ${({ theme }) => theme.color.WPrimary};
    }

    .swiper-pagination-current {
      position: absolute;
      top: -355px;
      right: 44px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      ::after {
        position: absolute;
        top: 50px;
        left: -17px;
        width: 50px;
        height: 0px;
        border: 0.5px solid #ffffff;
        transform: rotate(90deg);
        content: '';
      }
    }

    .swiper-pagination-total {
      position: absolute;
      top: -268px;
      right: 44px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .swiper-pagination-progressbar {
    position: absolute;
    left: 1388px;
    top: 154px;
    width: 2px;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.3);

    .swiper-pagination-progressbar-fill {
      width: 2px;
      background-color: ${({ theme }) => theme.color.WPrimary};
    }
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`

const ArrowButton = styled.div`
  position: absolute;
  left: 1270px;
  top: 844px;
  width: 140px;
  height: 150px;
  z-index: 2;
  background: url('/assets/images/main/main/Shadow.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  cursor: pointer;
  @media all and (max-width: 768px) {
    background: url(/assets/icons/arrow_mo.png);
    top: 31rem;
    left: 17rem;
    background-repeat: no-repeat;
    background-position: center center;
    backdrop-filter: none;
    width: 7rem;
  }
`

const ArrowButton2 = styled.div`
  position: absolute;
  left: 1290px;
  top: 858px;
  width: 100px;
  height: 100px;
  z-index: 2;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background: url(/assets/images/main/main/arrow2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  cursor: pointer;

  @media all and (max-width: 768px) {
    display: none;
  }
`

const Container = styled.div`
  position: relative;
  width: 1440px;
  height: 1024px;
  background: url('/assets/images/main/SliderMain_back.png');

  .SwiperBody {
    position: absolute;
    top: 0;
  }

  @media all and (max-width: 768px) {
    margin-top: 4rem;
    background: url('/assets/images/main/mobile/mo.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 40rem;
    position: relative;
  }
`

const ContentBox = styled.div`
  position: absolute;
  top: 0;
  width: 700px;
  height: 440px;
  margin-top: 309px;
  padding: 70px 60px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
`

const Content = styled.div`
  width: 700px;
  margin-top: 309px;
  padding: 70px 60px;
`

const TitleE = styled.div`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 80px;
  line-height: 98px;
  color: ${({ theme }) => theme.color.WPrimary};
`

const TitleK = styled.div`
  margin-top: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  color: ${({ theme }) => theme.color.WPrimary};
`

const Img = styled.img`
  width: 1000px;
  height: 750px;
  margin-top: 154px;
  margin-left: 340px;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`
const MoImg = styled.img`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 4rem;
    right: 0;
    height: 75%;
    width: 19rem;
    background: url(/assets/images/main/main_mo.png);
  }
`
const MoTextBox = styled.div`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 10rem;
    left: 0;
    height: 13rem;
    width: 19rem;
    background: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    color: #fff;
    padding: 2rem;
    font-weight: 700;

    .en {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    .ko {
      font-size: 1rem;
      margin-top: 2rem;
    }
  }
`
