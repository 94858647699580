import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'

function Members() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/About/Members/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  const navigate = useNavigate()
  const clickBtn = () => {
    const win = window.open(`https://spresto.oopy.io/`, '_blank')
    win.focus()
  }

  const subtext =
    window.screen.width > 768
      ? `현재 8명의 인원이 마포구 사무실에서 근무하고 있으며,
자유롭고 치열하게 의견을 교환하며 계속해서 서비스를 발전시켜 나가고 있습니다.`
      : `현재 8명의 인원이 마포구 사무실에서\n근무하고 있으며, 자유롭고 치열하게 의견을 교환하며\n계속해서 서비스를 발전시켜 나가고 있습니다.`

  return (
    <>
      <PageTitle title="Company Members" subtext={subtext} />
      <MembersWrapper>
        <Content>
          <List>
            {data.map((data) => (
              <Item key={data.id}>
                <Img backgroundImg={data.img} />
                <Name>{data.name_en}</Name>
                <Position>{data.position}</Position>
                <Info>{data.content}</Info>
              </Item>
            ))}
          </List>
        </Content>
        <BtnBox>
          <DetailButton onClick={clickBtn}>
            에스프레스토의 여정에 합류하기
          </DetailButton>
        </BtnBox>
      </MembersWrapper>
    </>
  )
}

const MembersWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  background-color: ${(props) => props.theme.color.WPrimary};
  @media all and (max-width: 768px) {
    min-width: 100%;
  }
`
const Content = styled.article`
  margin: 180px 100px 0 100px;
  @media all and (max-width: 768px) {
    margin: 3rem 1rem;
  }
`
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`
const Item = styled.div`
  padding: 0 35px 120px 35px;
  width: 33.333%;
  text-align: center;
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-bottom: 3rem;
  }
`
const Img = styled.div`
  margin: 0 auto 30px auto;
  width: 280px;
  height: 280px;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  cursor: pointer;
  @media all and (max-width: 768px) {
    margin: 0 auto;
    width: 11.25rem;
    height: 11.25rem;
    filter: drop-shadow(0px 10px 20px rgba(0, 186, 199, 0.2));
  }
`
const Name = styled.p`
  margin-bottom: 10px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #414141;

  @media all and (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #666666;
    margin-top: 1.5rem;
    margin-bottom: 0;
    letter-spacing: 0.1em;
  }
`
const Position = styled.p`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #c4c4c4;

  @media all and (max-width: 768px) {
    font-weight: 500;
    letter-spacing: 0.1em;
    font-family: 'Noto Sans KR';
    line-height: 1.9rem;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    letter-spacing: 0.1em;
    color: #c4c4c4;
  }
`
const Info = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  color: #6d6d6d;
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 2rem;
    text-align: center;
    word-break: keep-all;
  }
`
const BtnBox = styled.div`
  display: flex;
  justify-content: center;
`
const DetailButton = styled.button`
  margin-bottom: 200px;
  width: 420px;
  height: 75px;
  background: ${(props) => props.theme.color.GPrimary};
  border-radius: 100px;
  border: none;
  color: ${(props) => props.theme.color.WPrimary};
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  box-shadow: 0px 15px 30px ${(props) => props.theme.color.GTransparency};
  cursor: pointer;

  @media all and (max-width: 768px) {
    font-size: 1rem;
    width: 18rem;
    height: 4rem;
    margin-bottom: 6rem;
    box-shadow: 0px 1px 30px rgb(0 186 199 / 30%);
  }
`
export default Members
