import { useEffect, useRef, useState } from 'react'

import styled from 'styled-components'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import PageTitle from '../../../components/PageTitle'

function Patent() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Business/Patent/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const subtext = `에스프레스토가 소유한 특허를 소개합니다.`

  return (
    <>
      <PageTitle title="Patent" subtext={subtext} />
      {window.screen.width > 768 ? (
        <PatentWrapper>
          <NavigationButton>
            <PrevButton ref={navigationPrevRef}>
              <img
                src="/assets/icons/prevbtn_blue.png"
                alt="이전 슬라이드 버튼"
              />
            </PrevButton>
            <SlideIndex className="slideIndex_patent" />
            <NextButton ref={navigationNextRef}>
              <img
                src="/assets/icons/nextbtn_blue.png"
                alt="다음 슬라이드 버튼"
              />
            </NextButton>
          </NavigationButton>
          <StyleSwiper
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current
              swiper.params.navigation.nextEl = navigationNextRef.current
            }}
            pagination={{
              type: 'fraction',
              el: '.slideIndex_patent',
              formatFractionCurrent: function (number) {
                if (number < 10) {
                  return '0' + number
                }
                return number
              },
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {data.map((data) => (
              <SwiperSlide key={data.id}>
                <Content>
                  <Certificate>
                    <img src={data.image} alt="특허증" />
                  </Certificate>
                  <Info>
                    <Stars>
                      <PatentText>{data.patent}</PatentText>
                      {data.stars}
                    </Stars>
                    <Number>{data.number}</Number>
                    <Text>{data.content}</Text>
                  </Info>
                </Content>
              </SwiperSlide>
            ))}
          </StyleSwiper>
        </PatentWrapper>
      ) : (
        <PatentWrapper>
          <StyleSwiper
            initialSlide={1}
            loop={true}
            pagination={{
              type: 'progressbar',
              el: '.slideIndex_tech4',
            }}
            // onSwiper={(s) => {
            //   setSwiper(s)
            // }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {data.map((data) => (
              <SwiperSlide key={data.id}>
                <Content>
                  <img
                    className="medal"
                    src="/assets/images/main/mobile/patent/medal.png"
                    alt="medal"
                  />
                  <Info>
                    <Name>{data.title}</Name>
                    <Number>{data.number}</Number>
                    <Text>{data.content}</Text>
                  </Info>
                </Content>
              </SwiperSlide>
            ))}
          </StyleSwiper>
          <SliderIndexBox>
            <SlideIndex2 className="slideIndex_tech4" />
          </SliderIndexBox>
        </PatentWrapper>
      )}
    </>
  )
}

const PatentWrapper = styled.section`
  position: relative;
  padding-top: 110px;
  min-width: 1440px;
  background-color: ${(props) => props.theme.color.WPrimary};

  @media all and (max-width: 768px) {
    min-width: 100%;
    background: url('/assets/images/business/mo/bg.png') no-repeat bottom;
    background-size: cover;
    background-position: 0 -1rem;
    padding: 0 2rem;
  }
`

//slider - prev&next button
const NavigationButton = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  position: absolute;
  width: 244px;
  height: 60px;
  top: -30px;
  right: 110px;
  z-index: 1;
  Button {
    border: none;
    outline: none;
    opacity: 0.8;
    cursor: pointer;
    background: none;
    transition: all 0.2s ease-in;
  }
  .swiper-button-disabled {
    opacity: 0.3;
    cursor: auto;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`
const PrevButton = styled.button``
const NextButton = styled.button``

const SlideIndex = styled.p`
  color: rgba(0, 186, 199, 0.5);
  text-align: center;
  line-height: 55px;
  font-family: Montserrat;
  span {
    display: inline-block;
  }
  .swiper-pagination-current {
    width: 42px;
    margin-right: 15px;
    color: #00bac7;
    font-weight: 600;
    font-size: 40px;
  }
  .swiper-pagination-total {
    width: 25px;
    margin-left: 5px;
    font-size: 16px;
    line-height: 60px;
    opacity: 0.5;
  }
`
//slider
const StyleSwiper = styled(Swiper)`
  margin-bottom: 170px;
  width: 1440px;
  height: 720px;
  background: url('/assets/images/business/Patent_back.png') no-repeat bottom;
  .swiper-slide {
    ${({ theme }) => theme.mixins.flexBox('row', 'center', '')};
  }

  .swiper-wrapper {
    margin-top: 5rem;
  }

  @media all and (max-width: 768px) {
    width: 100%;
    background: unset;
    height: 39rem;
    margin-bottom: 0;
  }
`
//slider content
const Content = styled.article`
  display: flex;
  justify-content: center;
  align-self: baseline;
  position: relative;
  padding-top: 40px;
  width: 100%;
  height: 560px;

  .medal {
    display: none;
  }

  @media all and (max-width: 768px) {
    /* box-shadow: 10px 15px 10px rgba(0, 186, 199, 0.2); */
    filter: drop-shadow(0px 3px 8px rgba(0, 186, 199, 0.2));
    margin: 0 auto;
    padding: 2rem 1rem;
    background-color: #fff;
    width: 19rem;
    height: 24rem;
    border-radius: 0.5rem;
    .medal {
      display: block;
      position: absolute;
      right: 2rem;
    }
  }
`
//slider content - image
const Certificate = styled.aside`
  position: absolute;
  left: 200px;
  top: 0px;
  width: 360px;
  border-radius: 10px;
  box-shadow: 0px 15px 30px rgba(0, 186, 199, 0.2);
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`
//slider content - text
const Info = styled.div`
  width: 660px;
  height: 100%;
  position: absolute;
  top: 120px;
  left: 650px;

  @media all and (max-width: 768px) {
    padding: 1rem 1rem;
    margin-right: 0;
    width: 100%;
    position: static;
    margin-top: 1rem;
  }
`
const PatentText = styled.span`
  margin-right: 10px;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: 2px;
`
const Stars = styled.div`
  margin-bottom: 10px;
  line-height: 38px;
  font-size: 24px;
  letter-spacing: 5px;
  color: ${(props) => props.theme.color.GPrimary};
`
const Number = styled.div`
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  @media all and (max-width: 768px) {
    font-size: 1rem;
    color: #999999;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`
const Text = styled.p`
  font-weight: 400;
  font-size: 26px;
  line-height: 50px;
  color: ${(props) => props.theme.color.TPrimary};
  @media all and (max-width: 768px) {
    font-size: 1rem;
    color: #666666;
    font-weight: 400;
    line-height: 1.9rem;
  }
`

export default Patent

const Name = styled.p`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  @media all and (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`

const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: -425px;
  line-height: 18px;
  font-family: Montserrat;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    position: absolute;
    width: 84%;
    bottom: 4.5rem;
    .swiper-pagination-progressbar {
      background: rgba(0, 186, 199, 0.3);
      height: 0.13rem;
    }
    .swiper-pagination-progressbar-fill {
      background: #00bac7;
    }
  }
`

const SlideIndex2 = styled.p`
  color: ${(props) => props.theme.color.GPrimary};
  text-align: center;
  line-height: 55px;
  font-family: Montserrat;
  span {
    display: inline-block;
  }
  .swiper-pagination-current {
    width: 40px;
    margin-right: 5px;
    font-weight: 600;
    font-size: 40px;
  }
  .swiper-pagination-total {
    width: 20px;
    margin-left: 5px;
    font-size: 16px;
    line-height: 60px;
    opacity: 0.5;
  }
`
