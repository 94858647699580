import styled from 'styled-components'

function PageTitle({ title, subtext, paddingT }) {
  return (
    <Header style={{ 'padding-top': paddingT }}>
      <Title>{title}</Title>
      <SubText>{subtext}</SubText>
    </Header>
  )
}

const Header = styled.header`
  padding: 180px 60px 0 60px;
  width: 1440px;
  @media all and (max-width: 768px) {
    width: 100vw;
    padding: 7rem 0rem 0 1.6rem;
  }
`
const Title = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: ${(props) => props.theme.color.GPrimary};
  @media all and (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`
const SubText = styled.p`
  margin-top: 40px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  white-space: pre-wrap;
  color: ${(props) => props.theme.color.TPrimary};
  @media all and (max-width: 768px) {
    margin-top: 1.5rem;
    font-size: 0.9rem;
    width: 21.5rem;
    word-break: keep-all;
    line-height: 2rem;
  }
`

export default PageTitle
