import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageTitle from '../../../components/PageTitle'

function Product() {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/data/Business/Product/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  const subtext = `기술이 발전하면서 "어디서든 나를 지켜볼 수 있다"는 불안감이 사람들 사이에 퍼지며,
우리는 종종 마음 편하게 쉬고 놀아야 할 공간에서도 마음을 놓지 못하는 상황을 경험하곤 합니다.
이런 사회적 문제를 해결하고, 모두가 다시 안심할 수 있도록 다양한 모바일 서비스와 프로덕트들을 만들고 있습니다.
`

  return (
    <>
      <PageTitle title="Product" subtext={subtext} />
      <ProductWrapper>
        <Content>
          {data.map((data) => (
            <PrdList key={data.id}>
              <PrdImg>
                <img src={data.image} alt={data.title} />
              </PrdImg>
              <PrdInfo>
                <Number>{data.number}</Number>
                {window.screen.width > 768 ? (
                  <Title>{data.title}</Title>
                ) : (
                  <Title>⎯&nbsp;&nbsp;{data.title}</Title>
                )}
                <SubTitle>{data.subtitle}</SubTitle>
                <img className="moImg" src={data.image} alt={data.title} />
                {data.id === 2 ? (
                  <Description
                    style={{ whiteSpace: 'pre-line', 'margin-top': '2rem' }}
                  >
                    {window.screen.width > 768
                      ? data.description_pc
                      : data.description_mo}
                  </Description>
                ) : (
                  <Description>
                    {window.screen.width > 768
                      ? data.description_pc
                      : data.description_mo}
                  </Description>
                )}

                <BtnBox>
                  {data.appstore_link && (
                    <AppStoreBtn
                      onClick={() =>
                        window.open(`${data.appstore_link}`, '_blank')
                      }
                    />
                  )}
                  {data.googleplay_link && (
                    <GooglePlayBtn
                      onClick={() =>
                        window.open(`${data.googleplay_link}`, '_blank')
                      }
                    />
                  )}
                  {data.purchase_link && (
                    <PurchaseBtn
                      onClick={() =>
                        window.open(`${data.purchase_link}`, '_blank')
                      }
                    />
                  )}
                  {data.touchsiren_link && (
                    <TouchSirenBtn
                      onClick={() =>
                        window.open(`${data.touchsiren_link}`, '_blank')
                      }
                    />
                  )}
                </BtnBox>
              </PrdInfo>
            </PrdList>
          ))}
        </Content>
      </ProductWrapper>
    </>
  )
}

const ProductWrapper = styled.section`
  position: relative;
  padding: 0 100px;
  min-width: 1440px;
  background-color: ${(props) => props.theme.color.WPrimary};
  @media all and (max-width: 768px) {
    min-width: 100%;
    height: 140rem;
    padding: 5rem 2rem;
  }
`
const Content = styled.article`
  margin: 180px 0 200px 0;
  main:nth-child(even) {
    flex-direction: row-reverse;
  }
  @media all and (max-width: 768px) {
    margin-top: -6rem;
    flex-direction: column;
  }
`
const PrdList = styled.main`
  display: flex;
  margin-top: 130px;
  height: 500px;
  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-top: 5rem;
    height: unset;
  }
`
const PrdImg = styled.div`
  width: 50%;

  @media all and (max-width: 768px) {
    display: none;
  }
`
const PrdInfo = styled.div`
  position: relative;
  padding: 0 40px;
  width: 50%;
  .moImg {
    display: none;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    padding: 0;
    .moImg {
      display: block;
      width: 100%;
    }
  }
`
const Number = styled.p`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;
  color: ${(props) => props.theme.color.GSecondary};
  opacity: 0.5;
  @media all and (max-width: 768px) {
    display: none;
  }
`
const Title = styled.p`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 48px;
  line-height: 48px;
  color: ${(props) => props.theme.color.GPrimary};
  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    color: #77cae4;
    margin-bottom: 0;
  }
`
const SubTitle = styled.p`
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: ${(props) => props.theme.color.TPrimary};

  @media all and (max-width: 768px) {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: #666666;
  }
`
const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: ${(props) => props.theme.color.TPrimary};
  white-space: pre-line;
  @media all and (max-width: 768px) {
    white-space: normal;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: 'Noto Sans KR';
    color: #666666;
    line-height: 2.1rem;
  }
`

const BtnBox = styled.div`
  position: absolute;
  bottom: 0;
  button {
    width: 250px;
    height: 53px;
    border: 1.5px solid ${(props) => props.theme.color.GPrimary};
    border-radius: 10px;
    cursor: pointer;
  }
  @media all and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    position: static;
    margin-top: 2.5rem;
    button {
      width: 9.5rem;
      background-size: 7.5rem;
      height: 3rem;
      margin-right: 0.3rem;
    }
  }
`
const AppStoreBtn = styled.button`
  margin-right: 30px;
  background: url('/assets/icons/appstore.png') no-repeat center;
`
const GooglePlayBtn = styled.button`
  background: url('/assets/icons/googleplay.png') no-repeat center;
`
const PurchaseBtn = styled.button`
  background: url('/assets/icons/naver.png') no-repeat center;
`

const TouchSirenBtn = styled.button`
  background: url('/assets/icons/touchsiren_homepage_btn.png') no-repeat center;
`

export default Product
