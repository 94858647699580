import styled from 'styled-components'

function Partnership() {
  return (
    <Container>
      <Header>
        <span>ABOUT US</span>
        <Title>Partnership</Title>
      </Header>
      <img
        className="mo"
        src="/assets/images/main/mobile/partnership/partner.png"
        alt="partners"
      />
      <Partner src="/assets/images/main/Partnership_01.png" />
      <Background src="/assets/images/main/Partnership_back_02.png" />
      <Background2 />
    </Container>
  )
}

export default Partnership

const Container = styled.div`
  position: relative;
  ${({ theme }) => theme.mixins.flexBox('column', 'center', 'flex-start')};
  width: 1440px;
  height: 1024px;
  background: url('/assets/images/main/Partnership_back_01.png');
  .mo {
    display: none;
  }
  @media all and (max-width: 768px) {
    .mo {
      display: block;
      width: 100%;
    }
    background: url('/assets/images/main/mobile/partnership/bg.png');
    background-size: cover;
    width: 100%;
    height: 61rem;
    padding: 0;
    .bg {
      display: block;
      width: 19rem;
      position: absolute;
      right: 0;
      top: 16rem;
    }
  }
`
const Header = styled.div`
  z-index: 2;
  ${({ theme }) => theme.mixins.flexBox('column')};
  margin-top: 230px;

  span {
    display: block;
    width: 155px;
    height: 43px;
    background-color: #edfcfd;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }

  @media all and (max-width: 768px) {
    margin-top: 7rem;
    span {
      background-color: #c6f4f8;
    }
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  z-index: 2;
  color: ${({ theme }) => theme.color.GPrimary};
  @media all and (max-width: 768px) {
    font-size: 2.8rem;
  }
`
const Partner = styled.img`
  z-index: 2;
  margin-top: 20px;
  width: 1320px;

  @media all and (max-width: 768px) {
    display: none;
  }
`
const Background = styled.img`
  position: absolute;
  top: 100px;
  z-index: 1;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const Background2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  /* top: 0px;
  z-index: 0; */
  background-image: url('/assets/images/main/partner/bg2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80px 0;
  z-index: 0;

  @media all and (max-width: 768px) {
    display: none;
  }
`
