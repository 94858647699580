import styled from 'styled-components'
import PageTitle from '../../components/PageTitle'

function Contact() {
  const subtext = `궁금한 점이나 제안, 취재 요청 등 모든 문의는 하단으로 연락바랍니다.`

  return (
    <>
      <PageTitle title="Contact" subtext={subtext} />
      <ContactWrapper>
        <Section>
          <LeftSectionWrapper>
            <IconBox>
              <Icon backgroundImg="/assets/icons/contactus.png" />
            </IconBox>
            <DescBox>
              <ContactTitle>CONTACT</ContactTitle>
              <ContactDesc>02-6954-1013 ┃ contact@spresto.net</ContactDesc>
            </DescBox>
          </LeftSectionWrapper>
          <LeftSectionWrapper className="loc" style={{ 'margin-top': '25px' }}>
            <IconBox>
              <Icon backgroundImg="/assets/icons/direction.png" />
            </IconBox>
            <DescBox>
              <ContactTitle>LOCATION</ContactTitle>
              <ContactDesc>
                서울특별시 마포구 동교로 174 청하빌딩 10층
              </ContactDesc>
              <BtnWrapper>
                <Btn
                  type="button"
                  onClick={() => {
                    window.open(`https://naver.me/xI2hPDdL`, '_blank')
                  }}
                >
                  네이버 지도 보기
                </Btn>
                <Btn
                  type="button"
                  onClick={() => {
                    window.open(`http://kko.to/y1piwtbLcI`, '_blank')
                  }}
                >
                  카카오맵 보기
                </Btn>
              </BtnWrapper>
            </DescBox>
          </LeftSectionWrapper>
        </Section>

        <Section className="map">
          <MapImg backgroundImg="/assets/images/contact/map.png" />
          <BtnWrapper2>
            <Btn
              type="button"
              onClick={() => {
                window.open(`https://naver.me/xI2hPDdL`, '_blank')
              }}
            >
              네이버 지도 보기
            </Btn>
            <Btn
              style={{ 'margin-left': '1rem' }}
              type="button"
              onClick={() => {
                window.open(`http://kko.to/y1piwtbLcI`, '_blank')
              }}
            >
              카카오맵 보기
            </Btn>
          </BtnWrapper2>
        </Section>
      </ContactWrapper>
    </>
  )
}
const ContactWrapper = styled.section`
  position: relative;
  min-width: 1440px;
  height: 550px;
  padding: 0 60px 0 60px;
  background-color: ${(props) => props.theme.color.WPrimary};
  display: flex;
  justify-content: space-around;
  margin-bottom: 180px;
  @media all and (max-width: 768px) {
    min-width: 100%;
    flex-direction: column;
    padding: 0 1.5rem;

    .map {
      margin-top: 0 !important;
    }
  }
`
const Section = styled.article`
  height: 400px;
  width: 600px;
  margin-top: 120px;
  @media all and (max-width: 768px) {
    margin-top: 4rem;
    width: 100%;
    height: 100%;

    .loc {
      margin-top: 0 !important;
    }
  }
`
const MapImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
`

const LeftSectionWrapper = styled.div`
  padding: 0 auto 30px auto;
  display: flex;
  width: 100%;
  height: 50%;
`

const IconBox = styled.div``

const Icon = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  @media all and (max-width: 768px) {
    width: 3rem;
    height: 3rem;
  }
`

const DescBox = styled.div`
  margin: 0 auto 30px 40px;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
  @media all and (max-width: 768px) {
    margin-left: 1.5rem;
  }
`

const ContactTitle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  width: 300px;
  height: 40px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.2em;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #77cae4;
  @media all and (max-width: 768px) {
    font-size: 1.6rem;
    width: 100%;
    margin-top: 0;
  }
`

const ContactDesc = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #666666;

  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    width: 21rem;
    margin-left: -4.3rem;
  }
`

const BtnWrapper = styled.div`
  width: 450px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    display: none;
  }
`

const BtnWrapper2 = styled.div`
  width: 450px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`

const Btn = styled.button`
  background: ${({ theme }) => theme.color.WPrimary};
  width: 216px;
  height: 53px;
  border: 1.5px solid #00bac7;
  border-radius: 10px;
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #00bac7;
  cursor: pointer;
  @media all and (max-width: 768px) {
    font-size: 0.9rem;
    width: 10rem;
    height: 3rem;
  }
`

export default Contact
