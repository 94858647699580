import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Pagination, Navigation } from 'swiper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function SliderNews() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [swiper, setSwiper] = useState(null)
  const clickBtn = () => {
    navigate('/about/news')
  }
  useEffect(() => {
    fetch('/data/About/News/data.json', { mode: 'cors' })
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        if (swiper) {
          const timer = setTimeout(() => {
            swiper.slideTo(3)
          }, 1)
          return () => clearTimeout(timer)
        }
      })
  }, [swiper])

  return (
    <Container>
      <Header>
        <span className="title">ABOUT US</span>
        <Title>Latest News</Title>
        <SliderIndexBox>
          <hr />
          <SlideIndex className="slideIndex_tech3" />
        </SliderIndexBox>
      </Header>
      <img
        className="bg"
        src="/assets/images/main/mobile/news/Background.png"
        alt=""
      />
      <StyleSwiper
        loop={true}
        initialSlide={1}
        pagination={{
          type: 'progressbar',
          el: '.slideIndex_tech3',
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {data.map((data) => (
          <SwiperSlide key={data.id}>
            <Content
              onClick={() => {
                window.open(`${data.url}`)
              }}
            >
              <div className="black" />
              <img src={data.img} alt="video" />

              <div className="date">{data.date}</div>
              <div className="title">{data.title}</div>
            </Content>
          </SwiperSlide>
        ))}
      </StyleSwiper>
      <StyledSwiper
        onSwiper={(s) => {
          setSwiper(s)
        }}
        modules={[Pagination, Navigation]}
        slidesPerView={3}
        navigation={true}
        pagination={true}
        loop={true}
        className="mySwiper"
      >
        {data.map((data) => {
          return (
            <SwiperSlide key={data.id}>
              <CardContainer
                BackgroundImg={data.img}
                onClick={() => {
                  window.open(`${data.url}`)
                }}
              >
                <div>
                  <Date>{data.date}</Date>
                  <CardTitle>{data.title}</CardTitle>
                  <span>자세히보기</span>
                </div>
              </CardContainer>
            </SwiperSlide>
          )
        })}
      </StyledSwiper>
      <ViewMoreBtn onClick={clickBtn}>
        VIEW MORE
        <span>
          <img src="/assets/icons/mo_right_arrow.png" alt="arrow" />
        </span>
      </ViewMoreBtn>
    </Container>
  )
}

export default SliderNews

const Container = styled.div`
  position: relative;
  width: 1440px;
  height: 1024px;
  padding: 0 80px;
  background-image: url('/assets/images/main/SliderNews_back.png');
  .bg {
    display: none;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    height: 55rem;
    background: #fff;
    padding: 0;
    .bg {
      display: block;
      width: 19rem;
      position: absolute;
      right: 0;
      top: 16rem;
    }
  }
`

const Header = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column')};

  .title {
    display: block;
    width: 155px;
    height: 43px;
    margin-top: 120px;
    background-color: #edfcfd;
    border-radius: 100px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 43px;
    letter-spacing: 0.3em;
    color: ${({ theme }) => theme.color.GPrimary};
  }

  @media all and (max-width: 768px) {
    .title {
      margin-top: 4rem;
    }
  }
`

const Title = styled.h1`
  margin-top: 20px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: ${({ theme }) => theme.color.GPrimary};
  @media all and (max-width: 768px) {
    font-size: 2.8rem;
  }
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  transition: width 200ms ease-in-out;

  .swiper-wrapper {
    width: 1440px;
    height: 510px;
    margin-top: 100px;
  }

  .swiper-slide {
    ${({ theme }) => theme.mixins.flexBox}
  }

  .swiper-button-prev {
    position: absolute;
    top: 360px;
    left: 0px;
    width: 60px;
    height: 60px;
    filter: drop-shadow(0px 30px 60px rgba(0, 186, 199, 0.2));
    background: url('/assets/icons/prevbtn_blue.png') no-repeat;
  }

  .swiper-button-next {
    position: absolute;
    top: 360px;
    right: -10px;
    width: 60px;
    height: 60px;
    background: url('/assets/icons/nextbtn_blue.png') no-repeat;
    filter: drop-shadow(0px 30px 60px rgba(0, 186, 199, 0.2));
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: none;
  }

  .swiper-pagination {
    position: absolute;
    top: 700px;

    .swiper-pagination-bullet {
      background-color: #848282;
    }

    .swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.color.GPrimary};
    }
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`

const CardContainer = styled.div`
  ${({ theme }) => theme.mixins.flexBox('column', 'flex-start', 'flex-end')}
  width: 400px;
  height: 390px;
  background-image: url(${(props) => props.BackgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 16px;
  color: ${({ theme }) => theme.color.WPrimary};
  cursor: pointer;
  filter: drop-shadow(0px 30px 60px rgba(0, 186, 199, 0.08));
  div {
    padding: 20px 32px;
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 100%);
    border-radius: 16px;
  }
`

const Date = styled.span`
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
`

const CardTitle = styled.h3`
  margin-bottom: 48px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const StyleSwiper = styled(Swiper)`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    position: static;
    margin-left: 0;
    width: 18rem;
    border-radius: 20px;
    height: 30rem;
    margin: 0rem auto;

    img {
      width: 100%;
    }
  }
`
const Content = styled.p`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    position: relative;
    margin-top: 40px;
    font-weight: 500;
    font-size: 22px;
    line-height: 50px;
    word-break: keep-all;
    text-align: center;
    margin-top: 6rem;
    img {
      width: 18rem;
      height: 18rem;
      border-radius: 1.5rem;
      object-fit: cover;
    }
    .black {
      position: absolute;
      width: 100%;
      bottom: 0.9rem;
      height: 10rem;
      padding: 20px 32px;
      background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 100%);
      border-radius: 16px;
    }
    .date {
      position: absolute;
      top: 11rem;
      left: 1.5rem;
      color: white;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
    .title {
      position: absolute;
      top: 13rem;
      left: 1.5rem;
      color: white;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
      max-width: 15rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 0.8rem;
    }
  }
`
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: -425px;
  line-height: 18px;
  font-family: Montserrat;
  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    bottom: -33.3rem;
    width: 84%;

    hr {
      display: none;
    }

    .swiper-pagination-progressbar {
      background: rgba(0, 186, 199, 0.3);
      height: 0.13rem;
    }
    .swiper-pagination-progressbar-fill {
      background: #00bac7;
    }
  }
`
const SlideIndex = styled.p`
  display: none;
  display: flex;
  justify-content: space-between;
  width: 115px;
  color: transparent;
  text-align: center;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.color.WPrimary};
  }
  span::before {
    content: '0';
  }

  @media all and (max-width: 768px) {
    display: block;
    span::before {
      content: '';
    }
  }
`

const ViewMoreBtn = styled.article`
  display: none;
  @media all and (max-width: 768px) {
    float: right;
    display: block;
    color: #00bac7;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5rem;
    letter-spacing: 0.3em;
    padding-right: 2rem;
    span {
      margin-left: 0.5rem;
    }
  }
`
