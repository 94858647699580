import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

import styled from 'styled-components'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

function SliderTechnology() {
  const [data, setData] = useState([])
  const [playBtnClickUrl, setPlayBtnClickUrl] = useState(
    'https://www.youtube.com/watch?v=tAT1cFQ72bw'
  )
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    fetch('/data/Main/SliderTechnology/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        if (swiper) {
          const timer = setTimeout(() => {
            swiper.slideTo(1)
          }, 1)
          return () => clearTimeout(timer)
        }
      })
  }, [swiper])

  const navigate = useNavigate()
  const clickBtn = () => {
    navigate('/business/tech')
  }
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <PatentWrapper>
      <img
        className="bg"
        src="/assets/images/main/mobile/tech/backtor.png"
        alt=""
      />
      <Header>
        <Label>
          <hr />
          business
        </Label>
        <Title>Technology</Title>
        <SubText>에스프레스토는 AI 기술을 통해 문화를 선도합니다.</SubText>
        <DetailButton onClick={clickBtn}>기술 소개 더보기</DetailButton>
        <SliderIndexBox>
          <hr />
          <SlideIndex className="slideIndex_tech" />
        </SliderIndexBox>
      </Header>
      <NavigationButton>
        <PrevButton ref={navigationPrevRef}>
          <img
            src="/assets/images/main/tech/left_arrow.png"
            alt="이전 슬라이드 버튼"
          />
        </PrevButton>
        <PlayButton>
          <img
            onClick={() => window.open(`${playBtnClickUrl}`, '_blank')}
            src="/assets/images/main/tech/play.png"
            alt="재생 버튼"
          />
        </PlayButton>
        <NextButton ref={navigationNextRef}>
          <img
            src="/assets/images/main/tech/right_arrow.png"
            alt="다음 슬라이드 버튼"
          />
        </NextButton>
      </NavigationButton>
      {/* indicator만 다름  */}
      {window.screen.width > 768 ? (
        <StyleSwiper
          initialSlide={2}
          onSwiper={(s) => {
            setSwiper(s)
          }}
          loop={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }}
          pagination={{
            type: 'fraction',
            el: '.slideIndex_tech',
          }}
          onSlideChange={(e) => {
            if (e.activeIndex < data.length) {
              setPlayBtnClickUrl(data[e.activeIndex].link)
            }
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <MoPlayButton>
            <img
              onClick={() => window.open(`${playBtnClickUrl}`, '_blank')}
              src="/assets/icons/playbtn.png"
              alt="재생 버튼"
            />
          </MoPlayButton>
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <Content>
                <img
                  src={data.thumbnail}
                  onClick={() => window.open(`${data.link}`, '_blank')}
                  alt="video"
                />
              </Content>
            </SwiperSlide>
          ))}
        </StyleSwiper>
      ) : (
        <StyleSwiper
          onSwiper={(s) => {
            setSwiper(s)
          }}
          loop={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current
            swiper.params.navigation.nextEl = navigationNextRef.current
          }}
          pagination={{
            type: 'progressbar',
            el: '.slideIndex_tech',
          }}
          onSlideChange={(e) => {
            const index =
              e.activeIndex === 5
                ? 3
                : e.activeIndex === 0
                ? 3
                : e.activeIndex - 1

            if (data.length > 0) {
              setPlayBtnClickUrl(data[index].link)
            }
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <MoPlayButton>
            <img
              onClick={() => window.open(`${playBtnClickUrl}`, '_blank')}
              src="/assets/icons/playbtn.png"
              alt="재생 버튼"
            />
          </MoPlayButton>
          {data.map((data) => (
            <SwiperSlide key={data.id}>
              <Content>
                <img
                  src={data.thumbnail}
                  onClick={() => window.open(`${data.link}`, '_blank')}
                  alt="video"
                />
              </Content>
            </SwiperSlide>
          ))}
        </StyleSwiper>
      )}
      <ViewMoreBtn onClick={clickBtn}>
        VIEW MORE
        <span>
          <img src="/assets/icons/mo_right_white_arrow.png" alt="arrow" />
        </span>
      </ViewMoreBtn>
    </PatentWrapper>
  )
}

const PatentWrapper = styled.section`
  position: relative;
  padding: 60px;
  min-width: 1440px;
  height: 1024px;
  background-image: url('/assets/images/main/SliderTechnology_back.png');
  .bg {
    display: none;
  }
  @media all and (max-width: 768px) {
    padding: 5rem 2rem;
    min-width: 100%;
    height: 57rem;
    .bg {
      display: block;
      width: 100vw;
      position: absolute;
      left: 0;
      top: 13rem;
    }
  }
`
//title
const Header = styled.header`
  position: relative;
  margin-top: 180px;
  padding-bottom: 643px;
  color: ${(props) => props.theme.color.WPrimary};

  @media all and (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`
const Label = styled.div`
  hr {
    display: inline-block;
    position: absolute;
    top: 2px;
    left: -45px;
    width: 30px;
    height: 2px;
    border: none;
    background-color: ${(props) => props.theme.color.WPrimary};
  }
  position: relative;
  margin-left: 40px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 5px;
`
const Title = styled.p`
  font-family: Montserrat;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  @media all and (max-width: 768px) {
    font-size: 2.4rem;
  }
`
const SubText = styled.p`
  margin-top: 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  @media all and (max-width: 768px) {
    max-width: 15rem;
    font-size: 1rem;
    margin-top: 1rem;
    word-break: keep-all;
    line-height: 2.3rem;
  }
`
const DetailButton = styled.button`
  position: absolute;
  width: 266px;
  height: 75px;
  top: 400px;
  left: 0;
  background: #ffffff;
  border-radius: 100px;
  border: none;
  color: ${(props) => props.theme.color.GPrimary};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
  @media all and (max-width: 768px) {
    display: none;
  }
`

//slider - prev&next button
const NavigationButton = styled.div`
  ${({ theme }) => theme.mixins.flexBox('row', 'center', 'space-between')};
  position: absolute;
  width: 460px;
  height: 60px;
  top: 440px;
  right: 200px;
  Button {
    border: none;
    outline: none;
    opacity: 1;
    cursor: pointer;
    background: none;
    transition: all 0.2s ease-in;
    z-index: 2;
    backdrop-filter: blur(25px);
    border-radius: 162px;
    width: 60px;
    height: 60px;
  }
  img {
    backdrop-filter: blur(25px);
    border-radius: 62px;
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`
const PrevButton = styled.button``
const NextButton = styled.button``
const PlayButton = styled.button``
const MoPlayButton = styled.button`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.2s ease-in;
    opacity: 0.8;
    outline: none;
    z-index: 2;
    border: 0;
    transform: translate(-50%, 70%);
    background: none;
    /* Note: backdrop-filter has minimal browser support */
    img {
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(25px);
      border-radius: 62px;
    }
  }
`
//slider - pagenation_fraction
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: -425px;
  line-height: 18px;
  font-family: Montserrat;
  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    hr {
      display: none;
    }

    .swiper-pagination-progressbar {
      background: rgba(255, 255, 255, 0.3);
      height: 0.13rem;
      margin-top: 3rem;
    }
    .swiper-pagination-progressbar-fill {
      background: #fff;
    }
  }
`
const SlideIndex = styled.p`
  display: flex;
  justify-content: space-between;
  width: 115px;
  color: transparent;
  text-align: center;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.color.WPrimary};
  }
  span::before {
    content: '0';
  }

  @media all and (max-width: 768px) {
    span::before {
      content: '';
    }
  }
`
//slider
const StyleSwiper = styled(Swiper)`
  position: absolute;
  left: 810px;
  top: 270px;
  width: 400px;
  height: 400px;
  border-radius: 20px;
  @media all and (max-width: 768px) {
    position: static;
    margin-left: 0;
    width: 18rem;
    height: 22rem;
    margin: 5rem auto;
  }
`
//slider content
const Content = styled.article`
  img {
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
  }
`

const ViewMoreBtn = styled.article`
  display: none;
  @media all and (max-width: 768px) {
    float: right;
    display: block;
    color: #fff;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3em;
    span {
      margin-left: 0.5rem;
    }
  }
`

export default SliderTechnology
