import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import { Pagination, Navigation, Autoplay, EffectFade } from 'swiper'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function SliderProduct() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const clickBtn = () => {
    navigate('/business/product')
  }
  useEffect(() => {
    fetch('/data/Main/SliderProduct/data.json')
      .then((res) => res.json())
      .then((data) => {
        setData(data)
      })
  }, [])

  return (
    <Container>
      <Header>
        <Label>
          <hr />
          business
        </Label>
        <Title>Product</Title>
        <SubText>
          {window.screen.width > 768
            ? `에스프레스토는 다방면의 기술혁신을 통해 안심가치를 창출하는 프로덕트를 만들고 있습니다.`
            : `에스프레스토는\n다방면의 기술혁신을 통해\n안심가치를 창출하는\n프로덕트를 만들고 있습니다.`}
        </SubText>
        <SliderIndexBox>
          <hr />
          <SlideIndex className="slideIndex_tech2" />
        </SliderIndexBox>
      </Header>
      <StyleSwiper
        loop={true}
        initialSlide={1}
        pagination={{
          type: 'progressbar',
          el: '.slideIndex_tech2',
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {data.map((data) => (
          <SwiperSlide key={data.id}>
            <Content>
              <img src={data.img} alt="video" />
            </Content>
          </SwiperSlide>
        ))}
      </StyleSwiper>
      <StyledSwiper
        modules={[Pagination, Navigation, Autoplay]}
        direction="vertical"
        slidesPerView={1}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'progressbar',
        }}
        className="mySwiper pc"
      >
        {data.map((data) => {
          return <SwiperSlide key={data.id} />
        })}
      </StyledSwiper>

      <StyledSwiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        direction="vertical"
        effect="fade"
        fadeEffect={{ crossFade: true }}
        slidesPerView={1}
        loop={true}
        speed={1000}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          type: 'fraction',
          formatFractionCurrent: function (number) {
            return ('0' + number).slice(-2)
          },
          formatFractionTotal: function (number) {
            return ('0' + number).slice(-2)
          },
          renderFraction: function (currentClass, totalClass) {
            return (
              '<span class="' +
              currentClass +
              '"></span>' +
              ' - ' +
              '<span class="' +
              totalClass +
              '"></span>'
            )
          },
        }}
        className="mySwiper SwiperBody"
      >
        {data.map((data) => {
          return (
            <SwiperSlide key={data.id}>
              <Img src={data.img} />
              <ContentWrapper>
                <Category>{data.category}</Category>
                <Title>{data.title}</Title>
                <Content>{data.content}</Content>
                <Button
                  type="button"
                  onClick={() => {
                    navigate(`/business/product`)
                  }}
                >
                  {data.button}
                </Button>
              </ContentWrapper>
            </SwiperSlide>
          )
        })}
      </StyledSwiper>
      <ViewMoreBtn onClick={clickBtn}>
        VIEW MORE
        <span>
          <img src="/assets/icons/mo_right_arrow.png" alt="arrow" />
        </span>
      </ViewMoreBtn>
    </Container>
  )
}

export default SliderProduct

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 1440px;
    height: 1024px;
  }

  .swiper-pagination {
    color: transparent;

    .swiper-pagination-current,
    .swiper-pagination-total {
      color: ${({ theme }) => theme.color.GPrimary};
    }

    .swiper-pagination-current {
      position: absolute;
      top: -355px;
      left: 60px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;

      ::after {
        position: absolute;
        top: 50px;
        left: -17px;
        width: 50px;
        height: 0px;
        border: 0.5px solid #00bac7;
        transform: rotate(90deg);
        content: '';
      }
    }

    .swiper-pagination-total {
      position: absolute;
      top: -268px;
      left: 60px;
      width: 19px;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .swiper-pagination-progressbar {
    position: absolute;
    left: 70px;
    top: 154px;
    width: 2px;
    height: 400px;
    background-color: ${({ theme }) => theme.color.GTransparency};

    .swiper-pagination-progressbar-fill {
      width: 2px;
      background-color: ${({ theme }) => theme.color.GPrimary};
    }
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`
const Container = styled.div`
  position: relative;
  width: 1440px;
  height: 1024px;
  background: url('/assets/images/main/SliderProduct_back.png');

  .SwiperBody {
    position: absolute;
    top: 0;
  }
  @media all and (max-width: 768px) {
    padding: 5rem 2rem;
    width: 100%;
    height: 52rem;
    background: url('/assets/images/main/mobile/product/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const Img = styled.img`
  width: 700px;
  margin-top: 240px;
  margin-left: 120px;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 180px;
  left: 868px;
  width: 480px;
  color: ${({ theme }) => theme.color.GPrimary};
  ${({ theme }) => theme.mixins.flexBox('column', 'flex-start')}
`

const Category = styled.h3`
  ::before {
    position: absolute;
    left: 0;
    top: 11px;
    width: 30px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.GPrimary};
    content: '';
  }
  padding-left: 42px;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.3em;
`

const Title = styled.h1`
  margin-top: 10px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  @media all and (max-width: 768px) {
    font-size: 2.4rem;
  }
`

const Content = styled.p`
  margin-top: 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  word-break: keep-all;
`

const Button = styled.button`
  margin-top: 240px;
  padding: 20px 50px;
  background: ${({ theme }) => theme.color.WPrimary};
  border: none;
  border-radius: 100px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.GPrimary};
  cursor: pointer;
`
const Header = styled.header`
  position: relative;
  margin-top: 180px;
  padding-bottom: 643px;
  color: #00bac7;
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    margin-top: 0;
    padding-bottom: 0;
  }
`

const Label = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;

    hr {
      display: inline-block;
      position: absolute;
      top: 2px;
      left: -45px;
      width: 30px;
      height: 2px;
      border: none;
      background-color: #00bac7;
    }
    position: relative;
    margin-left: 40px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #00bac7;

    line-height: 23px;
    letter-spacing: 5px;
  }
`
const SubText = styled.p`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    margin-top: 40px;
    font-weight: 500;
    color: #00bac7;
    white-space: pre-line;
    font-size: 22px;
    line-height: 2.5rem;
    max-width: 16rem;
    font-size: 1rem;
    margin-top: 1rem;
    word-break: keep-all;
  }
`

//slider - pagenation_fraction
const SliderIndexBox = styled.div`
  position: relative;
  width: 115px;
  height: 18px;
  bottom: -425px;
  line-height: 18px;
  font-family: Montserrat;
  hr {
    ${({ theme }) => theme.mixins.positionCenterX('absolute')};
    width: 50px;
    border: 0.1px solid white;
    background-color: white;
  }
  @media all and (max-width: 768px) {
    width: 100%;
    bottom: -21.3rem;
    hr {
      display: none;
    }

    .swiper-pagination-progressbar {
      background: rgba(0, 186, 199, 0.3);
      height: 0.13rem;
    }
    .swiper-pagination-progressbar-fill {
      background: #00bac7;
    }
  }
`
const SlideIndex = styled.p`
  display: none;
  display: flex;
  justify-content: space-between;
  width: 115px;
  color: transparent;
  text-align: center;
  span {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.color.WPrimary};
  }
  span::before {
    content: '0';
  }

  @media all and (max-width: 768px) {
    display: block;
    span::before {
      content: '';
    }
  }
`
//slider
const StyleSwiper = styled(Swiper)`
  display: none;

  @media all and (max-width: 768px) {
    display: block;
    position: static;
    margin-left: 0;
    width: 18rem;
    border-radius: 20px;
    height: 22rem;
    margin: 0rem auto;

    img {
      width: 100%;
    }
  }
`

const ViewMoreBtn = styled.article`
  display: none;
  @media all and (max-width: 768px) {
    float: right;
    display: block;
    color: #00bac7;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-top: 2rem;
    letter-spacing: 0.3em;
    span {
      margin-left: 0.5rem;
    }
  }
`
